<template>
    <b-modal size="xxl" ref="modal" hide-footer>
        <template v-slot:modal-title>
            {{ $t("tiers.edit_tiers_papper") }}
        </template>
        <div v-if="loading_modal">
            <LoadingSpinner />
        </div>
        <div v-else>
            <div>
                <div class="row mt-2 mb-5">
                    <label class="col-sm-3 col-form-label">{{ $t("tiers.recherche_pappers") }}</label>
                    <div class="col-sm-9">
                        <e-select
                            track-by="id"
                            label="label"
                            v-model="searchValue"
                            :options="pappers_result"
                            :placeholder="$t('global.rechercher')"
                            :loading="loading_pappers"
                            :sortable="false"
                            :internal-search="false"
                            preserve-search
                            reset-after
                            @search-change="onSearch"
                            @select="searchPappers"
                            ref="select_pappers"
                        >
                            <template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_address1 }} {{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_address1 }} {{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>
                </div>
                <div class="row mt-2" v-if="(!searched && pappers_result.length == 0) || (searched && searchValue && searchValue != '' && pappers_result.length == 0)">
                    <b-alert show variant="warning" class="col-12">
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
                        {{ $t('global.no_result') }}
                    </b-alert>
                </div>
            </div>
            <div v-if="base_tiers_data && base_tiers_data != {} && pappers_tiers && pappers_tiers.tiers_rs">
                <div class="row mt-3">
                    <div class="col-3"></div>
                    <div class="col-5">
                        <b-button variant="primary" @click.prevent="() => checkAll(false)">{{ $t("tiers.check_all_base") }}</b-button>
                    </div>
                    <div class="col-4">
                        <b-button variant="primary" @click.prevent="() => checkAll(true)">{{ $t("tiers.check_all_pappers") }}</b-button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-2">
                        <label>{{ $t("tiers.rs") }}</label>
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="1" 
                            v-model="take_pappers.tiers_rs" 
                            :value="false"
                            :unchecked-value="true"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_rs" class="form-control" v-model="base_tiers_data.tiers_rs" required  autocomplete="chrome-off">
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                        class="custom-control-inline"
                        name="1"
                        v-model="take_pappers.tiers_rs"
                        :value="true"
                        :unchecked-value="false"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_rs" class="form-control" v-model="pappers_tiers.tiers_rs" disabled  autocomplete="chrome-off">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-2">
                        <label>{{ $t('tiers.address1') }}</label>
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">    
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="2" 
                            v-model="take_pappers.tiers_address1" 
                            :value="false"
                            :unchecked-value="true"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_address1" class="form-control" v-model="base_tiers_data.tiers_address1" required  autocomplete="chrome-off">
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="2"
                            v-model="take_pappers.tiers_address1"
                            :value="true"
                            :unchecked-value="false"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_address1" class="form-control" v-model="pappers_tiers.tiers_address1" disabled  autocomplete="chrome-off">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-2">
                        <label>{{ $t('tiers.address2') }}</label>
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">    
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="3" 
                            v-model="take_pappers.tiers_address2" 
                            :value="false"
                            :unchecked-value="true"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_address2" class="form-control" v-model="base_tiers_data.tiers_address2" required  autocomplete="chrome-off">
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="3"
                            v-model="take_pappers.tiers_address2"
                            :value="true"
                            :unchecked-value="false"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_address2" class="form-control" v-model="pappers_tiers.tiers_address2" disabled  autocomplete="chrome-off">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-2">
                        <label>{{ $t('tiers.postalcode') }}</label>
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">    
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="4" 
                            v-model="take_pappers.tiers_postalcode" 
                            :value="false"
                            :unchecked-value="true"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_postalcode" class="form-control" v-model="base_tiers_data.tiers_postalcode" required  autocomplete="chrome-off">
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="4"
                            v-model="take_pappers.tiers_postalcode"
                            :value="true"
                            :unchecked-value="false"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_postalcode" class="form-control" v-model="pappers_tiers.tiers_postalcode" disabled  autocomplete="chrome-off">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-2">
                        <label>{{ $t('tiers.town') }}</label>
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">    
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="5" 
                            v-model="take_pappers.tiers_town" 
                            :value="false"
                            :unchecked-value="true"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_town" class="form-control" v-model="base_tiers_data.tiers_town" required  autocomplete="chrome-off">
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="5"
                            v-model="take_pappers.tiers_town"
                            :value="true"
                            :unchecked-value="false"
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="tiers_town" class="form-control" v-model="pappers_tiers.tiers_town" disabled  autocomplete="chrome-off">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-2">
                        <label>{{ $t('tiers.statut_rcs') }}</label>
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">    
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="6" 
                            v-model="take_pappers.tiers_statut_rcs" 
                            :value="false"
                            :unchecked-value="true"
                            disabled
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="statut_rcs" class="form-control" v-model="base_tiers_data.tiers_statut_rcs" disabled  autocomplete="chrome-off">
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="6"
                            v-model="take_pappers.tiers_statut_rcs"
                            :value="true"
                            :unchecked-value="false"
                            disabled
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="statut_rcs" class="form-control" v-model="pappers_tiers.tiers_statut_rcs" disabled  autocomplete="chrome-off">
                    </div>
                </div>       
                <div class="row mt-2">
                    <div class="col-2">
                        <label>{{ $t('tiers.siren') }}</label>
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">    
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="6" 
                            v-model="take_pappers.tiers_siren" 
                            :value="false"
                            :unchecked-value="true"
                            disabled
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="siren" class="form-control" v-model="base_tiers_data.tiers_siren" disabled  autocomplete="chrome-off">
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="6"
                            v-model="take_pappers.tiers_siren"
                            :value="true"
                            :unchecked-value="false"
                            disabled
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="siren" class="form-control" v-model="pappers_tiers.tiers_siren" disabled  autocomplete="chrome-off">
                    </div>
                </div>       
                <div class="row mt-2">
                    <div class="col-2">
                        <label>{{ $t('tiers.siret') }}</label>
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">    
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="6" 
                            v-model="take_pappers.tiers_siret" 
                            :value="false"
                            :unchecked-value="true"
                            disabled
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="siret" class="form-control" v-model="base_tiers_data.tiers_siret" disabled  autocomplete="chrome-off">
                    </div>
                    <div class="col-1 text-right align-self-end" align-self="center">
                        <b-form-checkbox 
                            class="custom-control-inline"
                            name="6"
                            v-model="take_pappers.tiers_siret"
                            :value="true"
                            :unchecked-value="false"
                            disabled
                        />
                    </div>
                    <div class="col-4">
                        <input type="text" id="siret" class="form-control" v-model="pappers_tiers.tiers_siret" disabled  autocomplete="chrome-off">
                    </div>
                </div>       
                <div class="text-center mt-5">
                    <b-button variant="primary" @click.prevent="checkForm">{{ $t("global.modifier") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'user-cog']"/></b-button>
                    <b-button variant="secondary" :disabled="processing" class="ml-2" @click.prevent="closeModal">{{ $t("global.annuler") }} <font-awesome-icon :icon="['fal', 'times']"/></b-button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script type="text/javascript">
import Tiers from "@/mixins/Tiers.js"
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'

    export default {
        name: "ModalTiersPappers",
        mixins: [Tiers],
        props:{
            base_tiers: { type: Object, default: () => { return {} }},
            from: { type: String, default: "" }
        },
        data(){
            return {
                base_tiers_data: _cloneDeep(this.base_tiers),
                processing: false,
                pappers_tiers: {
                    tiers_rs: '',
					tiers_address1: '',
					tiers_address2: '',
					tiers_postalcode: '',
					tiers_town: '',
					tiers_statut_rcs: '',
					tiers_siren: '',
					tiers_siret: '',
                },
				take_pappers: {
					tiers_rs: true,
					tiers_address1: true,
					tiers_address2: true,
					tiers_postalcode: true,
					tiers_town: true,
					tiers_statut_rcs: true,
					tiers_siren: true,
					tiers_siret: true,
				},
                debouncedSearch: _debounce(this.search, 300),
                pappers_result: [],
                searchValue: '',
                searched: false,
                loading_pappers: false,
                loading_modal: false,
                type: ""
            }
        },
        methods: {
            async checkForm() {
                if(!this.processing){
                    this.processing = true
                    const config = this.getConfig('api_config')
                    const pappers = config.find(conf => conf.apiconfig_type == "PAP")
                    let vat_number = null
                    if(pappers && this.pappers_tiers.tiers_siret) {
                        try{
                            vat_number = await this.getVATFromPappers(this.pappers_tiers.tiers_siret)
                        } catch (e) {
                            console.error(e) // On continue même sans avoir réussi à avoir le VAT number
                        }
                    }
                    try {
                        let tiers = {
                            tiers_rs: this.take_pappers.tiers_rs ? this.pappers_tiers.tiers_rs : this.base_tiers_data.tiers_rs,
                            tiers_address1: this.take_pappers.tiers_address1 ? this.pappers_tiers.tiers_address1 : this.base_tiers_data.tiers_address1,
                            tiers_address2: this.take_pappers.tiers_address2 ? this.pappers_tiers.tiers_address2 : this.base_tiers_data.tiers_address2,
                            tiers_postalcode: this.take_pappers.tiers_postalcode ? this.pappers_tiers.tiers_postalcode : this.base_tiers_data.tiers_postalcode,
                            tiers_town: this.take_pappers.tiers_town ? this.pappers_tiers.tiers_town : this.base_tiers_data.tiers_town,
                            tiers_statut_rcs: this.take_pappers.tiers_statut_rcs ? this.pappers_tiers.tiers_statut_rcs : this.base_tiers_data.tiers_statut_rcs,
                            tiers_date_creation: this.pappers_tiers.date_creation,
                            tiers_date_cessation: this.pappers_tiers.date_cessation,
                            tiers_siren: this.take_pappers.tiers_siren ? this.pappers_tiers.tiers_siren : this.base_tiers_data.tiers_siren,
                            tiers_siret: this.take_pappers.tiers_siret ? this.pappers_tiers.tiers_siret : this.base_tiers_data.tiers_siret,
                            phones: []
                        }
                        if (vat_number) tiers.tiers_vat_number = vat_number
                        await this.editTiersLight(this.base_tiers_data.tiers_id, tiers)
                        await this.$sync.force(true, true)
                        this.base_tiers.tiers_rs = tiers.tiers_rs
                        this.base_tiers.tiers_address1 = tiers.tiers_address1
                        this.base_tiers.tiers_address2 = tiers.tiers_address2
                        this.base_tiers.tiers_postalcode = tiers.tiers_postalcode
                        this.base_tiers.tiers_town = tiers.tiers_town
                        this.base_tiers.tiers_statut_rcs = tiers.tiers_statut_rcs
                        this.base_tiers.tiers_date_creation = tiers.tiers_date_creation
                        this.base_tiers.tiers_date_cessation = tiers.tiers_date_cessation
                        this.base_tiers.tiers_siren = tiers.tiers_siren
                        this.base_tiers.tiers_siret = tiers.tiers_siret
                    } catch (e) {
                        this.$refs.modal.hide()
                        return this.failureToast()
                    }
                    this.$refs.modal.hide()
                    this.processing = false
                    this.$emit('ok')
                    return this.successToast()
                }
            },
            checkAll(pappers){
                const new_take_pappers = {
					tiers_rs: pappers,
					tiers_address1: pappers,
					tiers_address2: pappers,
					tiers_postalcode: pappers,
					tiers_town: pappers,
					tiers_statut_rcs: true,
					tiers_siren: true,
					tiers_siret: true,
				}
                this.take_pappers = new_take_pappers
            },
            async openModal(baseTiers) {
                this.loading_modal = true
                this.$refs.modal.show()
                this.searched = false
                this.type = ""
                this.pappers_tiers = {}
                this.pappers_result = []
                this.base_tiers_data = baseTiers
                let search_val = ""
                if (baseTiers.tiers_siret && baseTiers.tiers_siret.length == 14){
                    search_val = baseTiers.tiers_siret
                    this.type = "SIRET"
                } else if (baseTiers.tiers_siren && baseTiers.tiers_siren.length == 9){
                    search_val = baseTiers.tiers_siren
                    this.type = "SIREN"
                } else if (baseTiers.tiers_rs){
                    search_val = baseTiers.tiers_rs
                    this.type = "RS"
                }
                this.searchValue = search_val
                if (search_val !== "" && this.type !== ""){
                    const pappers = await this.search(this.searchValue)
                }
                this.searchValue = ""
                this.loading_modal = false
                setTimeout(() => {
                    this.$refs.select_pappers ? this.$refs.select_pappers.$el.focus() : null
                }, 0);
            },
            async search(search_val) {
                if(!search_val) {
                    return
				}
                if (!isNaN(search_val) && search_val.length == 14){
                    this.type = "SIRET"
                } else if (!isNaN(search_val) && search_val.length == 9){
                    this.type = "SIREN"
                }
				this.loading_pappers = true                
				const pappers = this.searchAPIPappers(search_val, this.type, false, false)
                .then(res => {
                    if(res.resultats && res.resultats.length > 0) {
                        
                        this.pappers_result = res.resultats.map(tiers => {
                            return {
                                tiers_rs: tiers.nom_entreprise,
                                tiers_postalcode: tiers.siege.code_postal,
                                tiers_address1: tiers.siege.adresse_ligne_1,
                                tiers_address2: tiers.siege.adresse_ligne_2,
                                tiers_town: tiers.siege.ville,
                                tiers_siret: tiers.siege.siret,
                                tiers_siren: tiers.siren,
                                tiers_statut_rcs: this.tryTradRcs(tiers.statut_rcs),
                                tiers_date_creation: tiers.date_creation,
                                tiers_date_cessation: tiers.date_cessation,
                            }
                        })
                        if (this.type !== "RS") {
                            this.pappers_tiers = this.pappers_result[0]
                        }
                    }
                })

				// Ajout des résultats au e-select
				await Promise.all([pappers])

				this.loading_pappers = false
                if (!this.loading_modal) this.$refs.select_pappers.$el.focus()
			},
            onSearch(search_val) {
				this.debouncedSearch(search_val)
                this.type = "RS"
                this.searched = true
			},
            async searchPappers(option) {
				this.pappers_tiers.tiers_rs = option.tiers_rs
				this.pappers_tiers.tiers_address2 = option.tiers_address2
				this.pappers_tiers.tiers_address1 = option.tiers_address1
				this.pappers_tiers.tiers_town = option.tiers_town
				this.pappers_tiers.tiers_postalcode = option.tiers_postalcode
				this.pappers_tiers.tiers_siret = option.tiers_siret
				this.pappers_tiers.tiers_siren = option.tiers_siren
                this.pappers_tiers.tiers_statut_rcs = option.tiers_statut_rcs
                this.pappers_tiers.tiers_date_creation = option.tiers_date_creation
                this.pappers_tiers.tiers_date_cessation = option.tiers_date_cessation
			},
            tryTradRcs(string){
                const translated_string = this.$t('tiers.rcs.' + string)
                return ('tiers.rcs.' + string) === translated_string ? string : translated_string
            },
            closeModal() {
                this.$refs.modal.hide()
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
        }
    }
</script>